<template>
  <div 
    v-if="isLoaded()"
    class="
      duck-form 
      add--project--form
    "
  >
    <ProjectViewTitle 
      :title="project.name"
      titleClass="center"
    />

    <form
      @submit.prevent="saveForm"
    >
      <fieldset 
        class="
          form-block
          add--project--block
        "
      >
        <div 
          class="form-container"
        >
          <SmallTitle 
            title="วันเซ็นสัญญา"
            class="bottom-line"
          />
          <div 
            class="input"
          >
            <DateInput
              v-model="form.sign_date" 
            />
          </div>
          

          <SmallTitle 
            title="เอกสารจาก Shared Drive"
            class="mt-20 bottom-line"
          />
          <div 
            class="input"
          >
            <input
              v-model="form.agreement_drive" 
              type="text"
              placeholder="Shared drive link เช่น Google Drive, Dropbox เป็นต้น ..."
            >
          </div>
          
          <SmallTitle 
            title="upload ภายในระบบ Beaverman"
            class="
              mt-20 
              bottom-line
            "
          />
          <MultipleFileUploader 
            :parentId="projectId" 
            section="project"
            type='agreement' 
          />
          <FileUploaderRemark />

          <ProjectSubmitBlock text="บันทึกข้อมูลสัญญา" />
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
// load library and variables
import {apiRequest} from '@/utils/axios/axiosInstance.js';

// Load components
import ProjectViewTitle     from '@/components/project/view/block/ProjectViewTitle.vue';
import SmallTitle           from '@/components/common/SmallTitle.vue';
import MultipleFileUploader from '@/components/file/MultipleFileUploader.vue';
import FileUploaderRemark   from '@/components/file/FileUploaderRemark.vue';
import DateInput            from '@/components/common/form/input/DateInput.vue';
import ProjectSubmitBlock   from '@/components/project/form/button/ProjectSubmitBlock.vue';

// Load CSS
import '@/assets/css/project/project-form.css';

export default {
  data() {
    return {
      projectId: '',
      project: {},
      form: {
        project_id: '',
        sign_date: '',
        agreement_drive: '',
      }
    }
  },

  /**
   * Registration components
   */
  components: {
    ProjectViewTitle,
    DateInput,
    SmallTitle,
    FileUploaderRemark,
    MultipleFileUploader,
    ProjectSubmitBlock,
  },

  /**
   * Initial this component when created
   */
  created() {
    this.projectId = this.$route.params.id;
    this.form.project_id = this.projectId;

    // set page title
    this.$store.commit('setPageTitle', 'Edit Project');
    this.$store.commit('setHeadTitle', 'Edit Project : Agreement');

    this.setForm();
  },

  /**
   * Methods
   */
  methods: {
    /**
     * Detect page loaded
     */
    isLoaded() {
      return !this.$store.state.show_loader;
    },

    /**
     * Get project data
     */
    setForm() {
      this.$store.commit('setLoaderShow', true);

      apiRequest
        .get('/project/' + this.projectId)
        .then( (res) => {
          if (res.data.status != 200) {
            alert('การโหลดข้อมูลมีปัญหา กรุณาลองรีเฟรชหน้านี้ใหม่อีกครั้งหรือกด Back เพื่อกลับไปหน้าเดิมของท่าน !!!')
          }else{
            this.project = res.data.result;
            this.form.sign_date = this.project.sign_date;
            this.form.agreement_drive = this.project.agreement_drive;

            this.$store.commit('setLoaderShow', false);
          }
        });
    },

    /**
     * saveForm
     *    redirect to view project only
     */
    saveForm() {
      this.$store.commit('setLoaderShow', true);

      apiRequest
        .patch('/project/', this.form)
        .then( (res) => {
          if (res.data.status == 200) {
            this.$router.push('/project/view/' + this.projectId);
          } else {
            console.log(res.data);
            alert('เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณารีเฟรชหน้านี้และลองใหม่อีกครั้ง !!!');
          }
        });
    },
  }
}
</script>